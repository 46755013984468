import React from 'react';
import { gsap } from 'gsap';

export const MobileOverlayDecoration = () => {
  const halfRef = React.useRef(null);
  const curlRef = React.useRef(null);
  const asterixRef = React.useRef(null);
  const triangleRef = React.useRef(null);
  const zeroRef = React.useRef(null);
  const timelineRef = React.useRef(null);

  React.useLayoutEffect(() => {
    timelineRef.current = gsap
      .timeline({ defaults: { ease: 'power1.out' } })
      .from(
        halfRef.current,
        {
          y: 50,
          x: -40,
          rotate: '30deg'
        },
        0
      )
      .from(
        curlRef.current,
        {
          y: -100,
          x: -50,
          rotate: '40deg'
        },
        '<'
      )
      .from(
        asterixRef.current,
        {
          y: -100,
          x: 50,
          rotate: '200deg',
          transformOrigin: 'center center'
        },
        '<'
      )
      .from(
        triangleRef.current,
        {
          yPercent: 100
        },
        '<'
      )
      .from(
        zeroRef.current,
        {
          y: -100,
          x: 10,
          rotate: '250deg',
          transformOrigin: 'center'
        },
        '<'
      );
  }, []);
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 375 214"
      overflow="visible"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        ref={halfRef}
        d="M38.577 283.177L38.5555 283.199L38.5343 283.221C34.2273 287.728 26.7056 287.194 22.5328 281.717C0.917032 251.034 -2.74802 211.363 11.7358 177.755C16.9715 165.792 24.1212 154.667 33.7478 145.124L33.755 145.117L33.7622 145.11C61.7576 116.986 101.502 108.602 136.416 119.919L136.428 119.923C147.417 123.433 157.841 128.898 167.311 136.132L167.327 136.144L167.343 136.156C172.619 140.08 173.236 147.763 168.587 152.469C168.586 152.47 168.585 152.471 168.584 152.472L38.577 283.177Z"
        fill="#FF310D"
        stroke="#FF310D"
        strokeWidth="6.10813"
      />
      <path
        ref={triangleRef}
        d="M281.59 232.214C245.457 236.863 188.894 240.818 156.97 238.637C143.598 238.24 135.63 226.558 142.515 215.67C181.888 154.595 238.928 71.9921 281.63 22.1537C292.071 9.8302 311.305 14.9795 311.961 30.2737C313.871 79.3241 304.688 166.782 297.665 216.624C296.214 224.988 289.785 231.224 281.59 232.214Z"
        fill="#FFBC00"
      />
      <path
        ref={zeroRef}
        d="M171.115 116.233C184.587 116.233 194.954 112.332 202.217 104.531C209.374 96.6235 212.952 85.9227 212.952 72.4281C212.952 62.7289 211.689 53.504 209.163 44.7536C206.532 36.0032 202.743 28.3071 197.796 21.6652C192.849 14.9179 186.85 9.64654 179.798 5.85118C172.746 1.95039 164.8 0 155.959 0C143.118 0 133.172 3.9535 126.12 11.8605C118.963 19.7675 115.385 30.6264 115.385 44.4373C115.385 54.242 116.595 63.4668 119.016 72.1118C121.331 80.7568 124.857 88.4003 129.593 95.0421C134.224 101.579 140.013 106.744 146.96 110.54C153.906 114.335 161.958 116.233 171.115 116.233ZM170.641 103.74C164.326 103.74 158.695 102.158 153.749 98.9956C148.802 95.8328 144.592 91.5103 141.118 86.0282C137.645 80.4405 135.014 74.0622 133.225 66.8932C131.33 59.7242 130.383 52.1335 130.383 44.1211C130.383 34.1055 132.751 26.3567 137.487 20.8745C142.224 15.2869 148.696 12.4931 156.906 12.4931C163.221 12.4931 168.905 14.0745 173.957 17.2373C179.009 20.2946 183.324 24.5644 186.903 30.0466C190.481 35.4234 193.218 41.749 195.112 49.0234C197.007 56.1924 197.954 63.8886 197.954 72.1118C197.954 82.0219 195.533 89.7708 190.692 95.3584C185.85 100.946 179.167 103.74 170.641 103.74Z"
        fill="#FDF8E4"
      />
      <path
        ref={curlRef}
        d="M103.338 95.0224C101.244 89.8067 96.1828 84.7649 87.9808 84.7649C76.4632 84.7649 65.2945 95.0224 52.3808 95.0224C40.8632 95.0224 33.8828 87.025 29.6945 78.3323L35.8024 73.812C37.8965 78.8538 42.7828 84.2433 51.1592 84.2433C62.8514 84.2433 73.8455 74.1597 86.7593 74.1597C98.2769 74.1597 105.257 81.8094 109.446 89.9806L103.338 95.0224Z"
        fill="#FFBC00"
      />
      <path
        ref={asterixRef}
        d="M325.911 213.651L334.29 184.297L308.93 197.045L304.615 183.262L333.838 180.939L314.41 158.632L326.667 151.466L336.805 179.131L352.918 154.63L363.218 164.965L339.648 181.221L369.704 187.841L363.636 200.874L337.983 184.294L340.257 214.683L325.911 213.651Z"
        fill="#FDF8E4"
      />
    </svg>
  );
};

export const DesktopOverlayDecoration = () => {
  const halfRef = React.useRef(null);
  const curlRef = React.useRef(null);
  const asterixRef = React.useRef(null);
  const triangleRef = React.useRef(null);
  const zeroRef = React.useRef(null);
  const timelineRef = React.useRef(null);

  React.useLayoutEffect(() => {
    timelineRef.current = gsap
      .timeline({ defaults: { ease: 'power1.out' } })
      .from(
        halfRef.current,
        {
          y: 50,
          x: -100,
          rotate: '30deg'
        },
        0
      )
      .from(
        curlRef.current,
        {
          y: -200,
          x: -100,
          rotate: '-100deg'
        },
        '<'
      )
      .from(
        asterixRef.current,
        {
          x: 100,
          rotate: '200deg',
          transformOrigin: 'center center'
        },
        '<'
      )
      .from(
        triangleRef.current,
        {
          y: -200,
          x: 100,
          rotate: '-50deg'
        },
        '<'
      )
      .from(
        zeroRef.current,
        {
          y: 100,
          x: 100,
          rotate: '250deg',
          transformOrigin: 'center'
        },
        '<'
      );
  }, []);
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 355 299"
      overflow="visible"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        ref={triangleRef}
        d="M259.624 2.74676C285.876 12.8849 325.746 31.0117 346.624 44.2392C355.56 49.4295 356.673 60.2949 347.991 65.1567C298.778 92.148 229.644 127.26 182.304 145.396C170.678 149.923 159.51 139.349 164.693 128.72C181.444 94.7045 219.861 38.6858 242.971 7.41997C247.034 2.27275 253.695 0.403468 259.624 2.74676Z"
        fill="#FFBC00"
      />
      <path
        ref={zeroRef}
        d="M112.412 221.636C112.324 233.813 115.781 243.209 122.785 249.824C129.884 256.345 139.533 259.65 151.73 259.739C160.496 259.803 168.842 258.722 176.768 256.496C184.694 254.175 191.675 250.801 197.711 246.374C203.842 241.947 208.646 236.559 212.123 230.211C215.695 223.863 217.51 216.693 217.568 208.702C217.652 197.096 214.144 188.08 207.044 181.655C199.945 175.134 190.153 171.828 177.67 171.737C168.809 171.673 160.463 172.706 152.633 174.837C144.804 176.873 137.873 180.01 131.838 184.247C125.9 188.39 121.193 193.588 117.717 199.842C114.241 206.095 112.473 213.36 112.412 221.636ZM123.707 221.291C123.749 215.583 125.215 210.504 128.106 206.053C130.997 201.603 134.932 197.826 139.91 194.723C144.983 191.62 150.765 189.284 157.257 187.714C163.749 186.049 170.616 185.243 177.858 185.295C186.91 185.361 193.898 187.553 198.822 191.87C203.841 196.187 206.324 202.056 206.27 209.476C206.228 215.184 204.762 220.311 201.87 224.856C199.073 229.402 195.186 233.274 190.207 236.473C185.324 239.672 179.588 242.104 173.001 243.768C166.509 245.433 159.547 246.239 152.114 246.185C143.157 246.12 136.169 243.881 131.151 239.468C126.132 235.055 123.651 228.996 123.707 221.291Z"
        fill="#FDF8E4"
      />
      <path
        ref={curlRef}
        d="M112.693 142.643C120.406 139.607 127.895 132.189 127.983 120.08C128.106 103.076 113.101 86.4767 113.24 67.4112C113.364 50.4068 125.231 40.187 138.094 34.0969L144.694 43.1628C137.237 46.2004 129.237 53.3566 129.147 65.7234C129.022 82.9854 143.772 99.325 143.633 118.391C143.51 135.395 132.155 145.618 120.061 151.714L112.693 142.643Z"
        fill="#FFBC00"
      />
      <path
        ref={asterixRef}
        d="M277.155 164.404L252.687 162.929L269.226 147.067L259.983 140.108L250.272 161.688L238.7 140.97L229.947 148.368L248.098 163.459L225.192 169.156L230.2 179.741L248.903 166.177L245.725 190.779L257.228 189.651L251.679 165.733L274.03 175.578L277.155 164.404Z"
        fill="#FDF8E4"
      />
      <path
        ref={halfRef}
        d="M4.0654 119.474L4.06555 119.441L4.06504 119.408C3.98393 114.146 8.82862 109.933 14.6954 110.764C47.8863 116.771 75.2584 139.853 87.1893 170.503C91.388 181.471 93.8273 193.101 93.6857 205.275L93.6856 205.285L93.6856 205.296C93.5076 240.956 73.3598 271.387 43.8806 286.164L43.8681 286.17C34.6239 290.854 24.5062 293.932 13.8789 295.276L13.8579 295.279L13.8371 295.282C8.27336 296.058 3.29328 291.754 3.29918 286.104C3.29918 286.103 3.29918 286.101 3.29918 286.1L4.0654 119.474Z"
        fill="#FF310D"
        stroke="#FF310D"
        strokeWidth="6.59834"
      />
    </svg>
  );
};
