import { useStaticQuery, graphql } from 'gatsby';

export function useFooterData() {
  const { data } = useStaticQuery(graphql`
    query {
      data: footerDataJson {
        heading
        form {
          inputs {
            type
            name
            label
            placeholder
          }
          submitButtonText
        }
        agreementText
        enterpriseParagraph
        regularParagraph
        servicesParagraph
        socialLinks {
          name
          href
          icon {
            publicURL
          }
        }
        legalLink {
          name
          to
        }
        policyLink {
          name
          to
        }
        messages {
          success {
            headline
            text
          }
          error {
            headline
            text
          }
        }
        overlayLinks {
          name
          href
          icon {
            publicURL
          }
        }
      }
    }
  `);

  return data;
}
