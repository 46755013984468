// No nesting! - colors declarations can only be one level deep
// No variables - you can't reference the colors by var name (e.g. 'primary') within this file

const base = {
  blue: '#2A378C',
  yellow: '#FFBC00',
  biege: '#FDF8E4',
  red: '#FF310D'
};

const textColors = {
  text: '#2A378C', // blue
  heading: '#2A378C' // blue
};

export const colors = {
  // defaults
  ...base,
  ...textColors
};
