import React from 'react';

import { gsap } from 'gsap/all';

// pieces
import { Box, Button } from '@thepuzzlers/pieces';

export const ButtonCircleHover = ({
  blue = true,
  buttonText,
  onClick,
  wrapperRef,
  buttonRef,
  cursorRef,
  wrapperSx,
  disabled = false,
  ...props
}) => {
  const [cursorPos, setCursorPos] = React.useState({ x: 0, y: 0 });

  const mouseListener = (e) => {
    setCursorPos({
      x: e.clientX - 10,
      y: e.clientY - 10
    });
  };

  React.useEffect(() => {
    let button = wrapperRef.current;

    const scaleCursorIn = () => {
      gsap.to(cursorRef.current, {
        scale: 18,
        duration: 0.7
      });
      gsap.to(buttonRef.current, {
        color: blue ? '#FDF8E4' : '#2A378C',
        duration: 0.7
      });
    };

    const scaleCursorOut = () => {
      gsap.to(cursorRef.current, {
        scale: 1,
        duration: 0.7
      });
      gsap.to(buttonRef.current, {
        color: blue ? '#2A378C' : '#FDF8E4',
        duration: 0.7,
        clearProps: 'color'
      });
    };

    window.addEventListener('mousemove', mouseListener);
    button.addEventListener('mouseenter', scaleCursorIn);
    button.addEventListener('mouseleave', scaleCursorOut);

    return () => {
      window.removeEventListener('mousemove', mouseListener);
      button.removeEventListener('mouseenter', scaleCursorIn);
      button.removeEventListener('mouseleave', scaleCursorOut);
    };
  }, []);

  return (
    <Box
      ref={wrapperRef}
      className="get-in-touch-button"
      onClick={onClick}
      sx={{
        borderRadius: '8px',
        maskImage: 'linear-gradient(white, black)',
        ...wrapperSx
      }}
      {...props}>
      <Button
        ref={buttonRef}
        disabled={disabled}
        variant={blue ? 'secondary' : 'primary'}
        sx={{ position: 'relative', zIndex: 2 }}>
        {buttonText}
      </Button>
      <Box
        ref={cursorRef}
        sx={{
          position: 'fixed',
          backgroundColor: blue ? 'blue' : 'biege',
          borderRadius: '50%',
          height: '20px',
          width: '20px',
          pointerEvents: 'none',
          zIndex: 1,
          top: `${cursorPos.y}px`,
          left: `${cursorPos.x}px`
        }}
      />
    </Box>
  );
};
