export const typographyConfig = {
  // h3, h4 and paragraph are left in for the legal pages
  h3: {
    fontSizes: [33.6, 28.8, 37.6, 37.6, 44],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  h4: {
    fontSizes: [19.5, 24.5, 25.5, 18, 26.5, 28.5],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  paragraph: {
    fontSizes: [14.97, 15.29, 16.27, 14.1, 15.78, 15.82],
    fontFamily: 'body',
    lineHeight: 1.75
  },
  // Landing Page typographys
  'h1-500-135': {
    fontSizes: [26.2, 28.78, 38.29, 22.56, 37.12, 39.55],
    fontFamily: 'primary',
    lineHeight: '135%',
    '& > span': {
      fontFamily: 'secondary.italic'
    }
  },
  'h2-500-125': {
    fontSizes: [25.26, 28.78, 38.29, 22.56, 37.12, 39.55],
    fontFamily: 'primary',
    lineHeight: '125%',
    '& > span': {
      fontFamily: 'secondary.italic'
    }
  },
  'h2-700-125': {
    fontSizes: [29.94, 35.97, 42.11, 26.32, 44.54, 47.46],
    fontFamily: 'primary',
    lineHeight: '125%',
    '& > span': {
      fontFamily: 'secondary.italic'
    }
  },
  'h2-900-125': {
    fontSizes: [33.68, 35.97, 45.94, 30.08, 51.97, 55.37],
    fontFamily: 'primary',
    lineHeight: '125%',
    letterSpacing: '0.02em',
    '& > span': {
      fontFamily: 'secondary.italic'
    }
  },
  'h2-1000-110': {
    //used in footer headline
    fontSizes: [44.91, 43.17, 68.91, 52.63, 66.82, 71.19],
    fontFamily: 'primary',
    lineHeight: 1.1
  },
  'h3-500-100': {
    fontSizes: [29.94, 28.78, 30.63, 26.32, 29.7, 31.64],
    fontFamily: 'primary'
  },
  'h3-500-125': {
    fontSizes: [16.84, 16.19, 19.14, 15.04, 18.56, 19.77],
    fontFamily: 'secondary',
    lineHeight: 1.25
  },
  'h4-500-100': {
    fontSizes: [13.1, 14.39, 15.31, 13.16, 12.99, 13.84],
    fontFamily: 'secondary',
    lineHeight: '110%'
  },
  'h4-700-150': {
    fontSizes: [14.04, 14.39, 15.31, 13.16, 14.85, 15.82],
    fontFamily: 'secondary',
    lineHeight: '135%'
  },
  'h6-500': {
    fontSizes: [13.1, 12.59, 13.4, 13.16, 12.99, 13.84],
    fontFamily: 'secondary',
    lineHeight: '135%'
  },
  'h6-500-150': {
    //used in footer: servo
    fontSizes: [13.1, 12.59, 14.36, 14.1, 13.92, 13.84],
    fontFamily: 'secondary',
    lineHeight: 1.5,
    '& > span': {
      fontFamily: 'secondary.bold'
    }
  },
  'p-300-150': {
    fontSizes: [13.1, 12.59, 14.36, 13.16, 12.99, 13.84],
    fontFamily: 'body',
    lineHeight: 1.5
  },
  'p-500-100': {
    //used in footer: input-text
    fontSizes: [15.91, 14.39, 16.27, 14.1, 15.78, 15.82],
    fontFamily: 'body',
    lineHeight: 1
  },
  'p-500-150': {
    fontSizes: [14.04, 14.39, 16.27, 13.16, 15.78, 15.82],
    fontFamily: 'body',
    lineHeight: '150%',
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'p-500-150-1': {
    fontSizes: [14.97, 14.39, 16.27, 14.1, 15.78, 15.82],
    fontFamily: 'body',
    lineHeight: '150%',
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'p-500-150-2': {
    fontSizes: [14.97, 15.29, 16.27, 14.1, 15.78, 15.82],
    fontFamily: 'body',
    lineHeight: '150%',
    '& > span': {
      fontFamily: 'body.bold'
    }
  },
  'body-300-135': {
    fontSizes: [13.1, 12.59, 14.36, 14.1, 13.92, 13.84],
    fontFamily: 'body',
    lineHeight: 1.35
  },

  'body-300-150': {
    fontSizes: [13.1, 14.39, 15.31, 13.16, 13.92, 13.84],
    fontFamily: 'body',
    lineHeight: 1.5
  },
  'body-500': {
    fontSizes: [14.97, 14.39, 15.31, 15.04, 14.85, 15.82],
    fontFamily: 'body'
  },
  'tag-500-100': {
    //used for input label
    fontSizes: [14.04, 13.49, 14.36, 13.16, 14.85, 14.83],
    fontFamily: 'secondary',
    lineHeight: 1
  },
  'button-text': {
    //used in footer, sumbit button
    fontSizes: [16.84, 16.19, 19.14, 15.04, 18.56, 19.77],
    fontFamily: 'secondary',
    lineHeight: 1
  },
  'button-500-100': {
    fontSizes: [14.04, 15.29, 16.27, 14.1, 15.78, 17.8],
    fontFamily: 'secondary',
    lineHeight: 1,
    letterSpacing: '0.02em'
  },
  hyperLink: {
    fontSizes: [14.04, 13.49, 14.36, 14.1, 14.85, 14.83],
    fontFamily: 'secondary',
    lineHeight: 1.35
  }
};
