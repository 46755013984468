// const inputStyleDefault = {};
export const forms = {
  input: {
    variant: 'typography.p-500-100.normal',
    bg: 'transparent',
    borderBottom: ({ colors }) => `1px solid ${colors.biege}`,
    paddingBottom: ['12px', '12px', '8px', '8px', '8px', '2.214%'],
    paddingTop: ['12px', '12px', '8px', '8px', '8px', '2.214%'],
    color: ({ colors }) => `${colors.biege}`,
    '&::placeholder': {
      color: ({ colors }) => `${colors.biege}`,
      opacity: 0.5
    },
    '&:focus': {
      outline: 'none',
      borderBottom: ({ colors }) => `1px solid ${colors.yellow}`,
    }
  },
  label: {
    variant: 'typography.tag-500-100.bold' //doesn't give effect
  }
};
