export const fontFamilies = {
  body: {
    normal: 'cera-pro-normal-regular, sans-serif',
    bold: 'cera-pro-normal-bold, sans-serif'
  },
  primary: {
    normal: 'bridge-head-extended-bold, sans-serif'
  },
  secondary: {
    normal: 'freigeistregular, sans-serif',
    italic: 'freigeist-normal-italic, sans-serif',
    medium: 'freigeist-normal-medium, sans-serif',
    bold: 'freigeist-normal-bold, sans-serif'
  }
};
