const linkDefault = {
  cursor: 'pointer'
};

const buttonDefault = {
  cursor: 'pointer',
  transition: '0.2s ease-out'
};

const links = {
  hyperLink: {
    ...linkDefault,
    variant: 'typography.hyperLink.medium',
    color: ({ colors }) => `${colors.biege}`,
    ':hover,:focus,active': {}
  },
  navigation: {
    ...linkDefault,
    variant: 'typography.body-300-150.normal',
    color: ({ colors }) => `${colors.biege}`,
    ':hover,:focus,&.active': {}
  },
  footer: {
    ...linkDefault,
    variant: 'links.navigation',
    ':hover,:focus,&.active': {}
  }
};

const buttons = {
  primary: {
    ...buttonDefault,
    variant: 'typography.button-text.normal',
    bg: 'transparent',
    borderRadius: 'button',
    border: ({ colors }) => `solid ${colors.biege}`,
    borderWidth: '2px',
    color: 'biege',
    padding: [
      '10px 22px 10px 22px',
      '10px 22px 10px 22px',
      '10px 22px 10px 22px',
      '10px 22px 10px 22px',
      '10px 22px 10px 22px',
      'calc(10px + ((100vw - 1400px) * 10 / 2.5 / 1400)) calc(22px + ((100vw - 1400px) * 22 / 2.5 / 1400)) calc(10px + ((100vw - 1400px) * 10 / 2.5 / 1400)) calc(22px + ((100vw - 1400px) * 22 / 2.5 / 1400))'
    ]
  },
  secondary: {
    ...buttonDefault,
    variant: 'typography.button-500-100.bold',
    bg: 'transparent',
    borderRadius: 'button',
    border: ({ colors }) => `solid ${colors.blue}`,
    borderWidth: '2px',
    color: ({ colors }) => `solid ${colors.blue}`,
    padding: [
      '10px 14px 10px 14px',
      '14px 22px 14px 22px',
      '14px 22px 14px 22px',
      '10px 22px 10px 22px',
      '16px 30px 16px 30px',
      '12px 22px 12px 22px'
    ],
    whiteSpace: 'nowrap'
  },
  iconContainer: {
    ...buttonDefault,
    bg: 'transparent',
    border: 'none',
    display: 'block',
    padding: 0,
    margin: 0
  }
};

const cards = {
  primary: {}
};

export { links, buttons, cards };
