import React from 'react';
import Rive, { Layout, Fit, Alignment } from 'rive-react';

import { Box } from '@thepuzzlers/pieces';

export const RiveWrapper = ({ sx, riveSx, riveSrc }) => (
  <Box
    className="rive--wraper"
    sx={{
      position: 'relative',
      ...sx
    }}>
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        ...riveSx
      }}>
      <Rive
        src={riveSrc}
        autoplay={false}
        layout={
          new Layout({
            fit: Fit.FitWidth,
            alignment: Alignment.TopCenter
          })
        }
      />
    </Box>
  </Box>
);
