import React from 'react';
import {
  Overlay,
  GridWrapper,
  Link,
  Box,
  Image,
  fullWidthMinusMargins
} from '@thepuzzlers/pieces';
import { Headline, AgreementText, SocialLinks, GridSpacer } from '../Footer';
import { Form } from '../Footer/components/Form';
import closeIcon from '../../assets/elements/close-icon.svg';
import { IconButton } from 'components';
import { MobileOverlayDecoration, DesktopOverlayDecoration } from 'components';

export const GetInTouchOverlay = ({
  isOpen,
  handleClose,
  heading,
  form,
  agreementText,
  socialLinks,
  overlayLinks,
  messages
}) => {
  return (
    isOpen && (
      <Overlay
        handleClose={handleClose}
        containerSx={{ bg: 'gray', overflowY: 'scroll' }}
        shouldCloseOnBackdropClick={true} // true is the default
        shouldCloseOnEscapePress={true} // true is the default
      >
        <Box
          sx={{
            bg: 'blue',
            minHeight: '100%',
            paddingTop: ['37px', '39px', '39px', '36px', '36px', '40px'],
            position: 'relative'
          }}>
          <GridWrapper
            sx={{
              paddingBottom: ['0', '200px', '304px', '151px', '40px', '40px']
            }}>
            <OverlayLinks overlayLinks={overlayLinks} />
            <CloseButton handleClose={handleClose} />
            <GridSpacer
              height={[
                '20.69%',
                '28.31%',
                '37.76%',
                '6.39%',
                '17.92%',
                '15.32%'
              ]}
            />
            <OverlayHeadline heading={heading} />
            <OverlayForm form={form} messages={messages} />
            <OverlayAgreementText agreementText={agreementText} />
            <OverlaySocialLinks socialLinks={socialLinks} />
          </GridWrapper>
          <OverlayFooterDecoration />
        </Box>
      </Overlay>
    )
  );
};

const OverlayLinks = ({ overlayLinks }) => {
  return (
    <Box
      className="overlay-links"
      sx={{
        gridRow: 1,
        gridColumn: [
          '1 / span 7',
          '1 / span 5',
          '1 / span 7',
          '1 / span 19',
          '1 / span 14',
          '1 / span 11'
        ],
        display: [null, null, null, 'flex', 'flex', 'flex'],
        alignSelf: ['start', 'start', 'start', 'center', 'center', 'center'],
        '& > .overlay-external-link': {
          marginTop: ['12px', '12px', '24px', '0px', '0px', '0px'],
          marginLeft: [null, null, null, '24px', '24px', '32px'],
          '&:first-of-type': {
            marginTop: ['2px', '2px', '2px', '0px', '0px', '0px'],
            marginLeft: [null, null, null, '0px', '0px', '0px']
          }
        },
        zIndex: 2
      }}>
      {overlayLinks.map((link, index) => (
        <ExternalLink
          key={`overlay-external-link-${index}`}
          name={link.name}
          href={link.href}
          icon={link.icon?.publicURL || null}
        />
      ))}
    </Box>
  );
};

const ExternalLink = ({ name, href, icon }) => (
  <Link
    className="overlay-external-link"
    variant="hyperLink"
    href={href}
    sx={{ display: 'flex', alignItems: 'center' }}>
    {icon ? (
      <>
        <Image
          src={icon}
          alt=""
          sx={{ display: 'block', width: '20px', marginRight: '8px' }}
        />
        <span>{name}</span>
      </>
    ) : (
      <span>{name}</span>
    )}
  </Link>
);

const CloseButton = ({ handleClose }) => (
  <IconButton
    className="overlay-close-button"
    svg={closeIcon}
    onClick={handleClose}
    sx={{
      gridRow: 1,
      gridColumn: [
        '11 / span 2',
        '12 / span 1',
        '23 / span 2',
        '23 / span 2',
        '24 / span 1',
        '24 / span 1'
      ],
      width: ['55%', '80%', '50%', '50%', '80%', '90%'],
      alignSelf: ['start', 'start', 'start', 'center', 'center', 'center'],
      justifySelf: 'end',
      zIndex: 2
    }}
  />
);

const OverlayHeadline = ({ heading }) => (
  <Headline
    heading={heading}
    sx={{
      gridRow: 3,
      gridColumn: [
        '1 / span 12',
        '1 / span 4',
        '2 / span 8',
        '2 / span 7',
        '9 / span 5',
        '10 / span 4'
      ],
      zIndex: 2
    }}
  />
);

const OverlayForm = ({ form, messages }) => (
  <Form
    form={form}
    messages={messages}
    sx={{
      marginTop: ['7.52%', '10.10%', '9.45%', '3.53%', '7.02%', '11.60%'],
      gridColumn: [
        '1 / span 12',
        '6 / span 7',
        '13 / span 11',
        '12 / span 11',
        '16 / span 8',
        '16 / span 7'
      ],
      gridRow: [
        4,
        '3 / span 2',
        '3 / span 2',
        '3 / span 2',
        '3 / span 2',
        '3 / span 2'
      ],
      zIndex: 2
    }}
  />
);

const OverlayAgreementText = ({ agreementText }) => (
  <AgreementText
    agreementText={agreementText}
    sx={{
      gridRow: [5, 4, 4, 4, 4, 4],
      marginTop: ['12px', '0%', '0%', '0%', '0%', '0%'],
      alignSelf: ['start', 'end', 'end', 'end', 'end', 'end'],
      gridColumn: [
        '1 / span 12',
        '1 / span 4',
        '2 / span 9',
        '2 / span 8',
        '9 / span 6',
        '10 / span 4'
      ],
      zIndex: 2
    }}
  />
);

const OverlaySocialLinks = ({ socialLinks }) => (
  <SocialLinks
    socialLinks={socialLinks}
    sx={{
      gridColumn: [
        '1 / span 5',
        '1 / span 4',
        '2 / span 6',
        '2 / span 6',
        '9 / span 4',
        '10 / span 4'
      ],
      justifyContent: ['unset', 'unset', 'unset', 'unset', 'unset', 'unset'],
      marginTop: ['30.78%', '19.11%', '47.79%', '28.88%', '28.78%', '24%'],
      alignSelf: [null, 'start', 'start', 'start', 'start', 'start'],
      gridRow: [6, 5, 5, 5, 5, 5],
      zIndex: 2
    }}
  />
);

const OverlayFooterDecoration = () => {
  return (
    <GridWrapper
      className="overlay-decoration-container"
      sx={{
        width: '100%',
        position: [
          'static',
          'absolute',
          'absolute',
          'absolute',
          'absolute',
          'absolute'
        ],
        bottom: ['0px', '0px', '0px', '0px', '0px', '0px'],
        zIndex: 1
      }}>
      <Box
        className="footer-decoration"
        sx={{
          justifySelf: ['start', 'start', 'start', 'start', 'start', 'start'],
          marginTop: [
            '24.14%',
            '1.47%',
            '17.68%',
            '18.64%',
            '42.26%',
            '35.39%'
          ],
          gridColumn: [
            '1 / span 12',
            '5 / span 8',
            '11 / span 14',
            '14 / span 11',
            '1 / span 6',
            '1 / span 6'
          ],
          gridRow: [7, 6, 6, 5, '4 /span 3', '4 /span 3'],
          marginRight: ['4%', '0px', '0px', '0px', '0px', '0px'],
          width: ['117%', '100%', '100%', '100%', '145%', '130%'],
          transform: [
            null,
            null,
            null,
            null,
            'translateX(-3%)',
            'translateX(-3%)'
          ],
          marginLeft: [
            fullWidthMinusMargins[0],
            0,
            0,
            0,
            fullWidthMinusMargins[4],
            fullWidthMinusMargins[5]
          ]
        }}>
        {/* <Image
          className="mobile-decoration-svg"
          src={mobileDecorationSvg}
          alt=""
          sx={{ display: ['block', 'block', 'block', 'block', 'none', 'none'] }}
        /> */}
        <Box
          sx={{
            display: ['block', 'block', 'block', 'block', 'none', 'none'],
            overflowY: 'visible',
            '& > svg': {
              display: 'block',
              width: '100%'
            }
          }}>
          <MobileOverlayDecoration />
        </Box>
        {/* <Image
          className="desktop-decoration-svg"
          src={desktopDecorationSvg}
          alt=""
          sx={{ display: ['none', 'none', 'none', 'none', 'block', 'block'] }}
        /> */}
        <Box
          sx={{
            display: ['none', 'none', 'none', 'none', 'block', 'block'],
            overflowY: 'visible',
            '& > svg': {
              display: 'block',
              width: '100%'
            }
          }}>
          <DesktopOverlayDecoration />
        </Box>
      </Box>
    </GridWrapper>
  );
};
