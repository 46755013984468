import React from 'react';

// pieces
import { Box } from '@thepuzzlers/pieces';

// components
import { Vector } from './Vector';

// assets
import tilt from 'assets/elements/tilt.svg';

export const TiltSpacer = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        alignContent: 'center',
        gridTemplateColumns: '100%',
        gridTemplateRows: ['29.5vh', '23.5vh', '20vh', '42vh', '48vh', '44vh']
      }}>
      <Vector
        svg={tilt}
        alt=""
        sx={{
          justifySelf: 'center',
          width: ['20%', '20%', '12%', '10%', '10%', '10%']
        }}
      />
    </Box>
  );
};
