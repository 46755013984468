import React from 'react';
import PropTypes from 'prop-types';
import { CoreLayout } from 'gatsby-theme-thepuzzlers-core';
import { Box } from '@thepuzzlers/pieces';
import { useScrollTriggerRefresh } from 'hooks/useScrollTriggerRefresh';

// gsap
import { gsap } from 'gsap/all';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Sections
import { Footer, Navigation } from 'sections';

import 'gatsby-plugin-theme-ui/fonts/fontImports';

import '../styles.css';

gsap.registerPlugin(ScrollTrigger);

const Layout = ({ children, pageContext }) => {
  useScrollTriggerRefresh();
  return (
    // CoreLayout includes PageContextProvider & Global component
    <CoreLayout pageContext={pageContext}>
      <Navigation />
      <Box
        as="main"
        sx={{
          bg: 'biege',
          position: 'relative',
          borderRadius: '0 0 24px 24px',
          overflow: 'hidden',
          zIndex: 2
        }}>
        {children}
      </Box>
      <Footer />
    </CoreLayout>
  );
};

Layout.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Layout;
