import React from 'react';
import { useFooterData } from 'sections/Footer/useFooterData';

export const useGetInTouchOverlay = () => {
  const { heading, form, agreementText, socialLinks, overlayLinks, messages } =
    useFooterData();
  const [showOverlay, setShowOverlay] = React.useState(false);
  const handleOpenOverlay = () => setShowOverlay(true);
  const handleCloseOverlay = () => setShowOverlay(false);

  const overlayProps = {
    isOpen: showOverlay,
    handleClose: handleCloseOverlay,
    heading,
    form,
    agreementText,
    socialLinks,
    overlayLinks,
    messages
  };

  const overlayControl = {
    handleOpenOverlay,
    handleCloseOverlay
  };
  return [overlayProps, overlayControl];
};
