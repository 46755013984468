// external dependencies
import React from 'react';
import { gsap } from 'gsap/all';

// Pieces components
import {
  Box,
  Heading,
  Paragraph,
  Input,
  Label,
  FlexWrapper
} from '@thepuzzlers/pieces';

//project component
import { ButtonCircleHover } from 'components';

// Form States
const WAITING_FOR_SUBMIT = 'waiting';
const SUBMITTING = 'submitting';
const ERROR = 'error';
const SUCCESS = 'success';

export const Form = ({ form: { inputs, submitButtonText }, sx, messages }) => {
  const [formState, setFormState] = React.useState(WAITING_FOR_SUBMIT);
  const handleServerResponse = (ok, form) => {
    if (ok) {
      setFormState(SUCCESS);
      // Only rest form, if sending was successful
      form.reset();
    } else {
      setFormState(ERROR);
    }
  };

  const handleOnSubmit = (e) => {
    console.log('clicked');
    e.preventDefault();
    // return console.log('Form submitted', e.target);
    setFormState(SUBMITTING);
    const form = e.target;
    const formData = new FormData(form);

    fetch('https://getform.io/f/ba54c950-e0c4-4713-bf90-e89f30fb9826', {
      method: 'POST',
      body: formData
    })
      .then((response) => {
        // show loader a bit longer to avoid loading flash
        setTimeout(() => {
          if (response.ok) {
            handleServerResponse(true, form);
          } else {
            // todo
            handleServerResponse(false, form);
          }
        }, 400);
      })
      .catch(() => {
        setTimeout(() => {
          handleServerResponse(false, form);
        }, 400);
      });
  };

  return (
    <Box
      as="form"
      onSubmit={handleOnSubmit}
      id="get-in-touch-form"
      sx={{
        gridColumn: [
          '1 / span 12',
          '6 / span 7',
          '13 / span 11',
          '12 / span 11',
          '16 / span 8',
          '17 / span 7'
        ],
        gridRow: [
          3,
          '2 / span 2',
          '2 / span 2',
          '2 / span 2',
          '2 / span 2',
          '2 / span 2'
        ],
        marginTop: ['16.3%', '10.105%', '9.45%', '3.53%', '7.02%', '11.6%'],
        '& > .input-group': {
          marginTop: [
            '12.54%',
            '13.47%',
            '12.61%',
            '12.85%',
            '12.21%',
            '11.05%'
          ],
          '&:first-of-type': {
            marginTop: '0px'
          }
        },
        ...sx
      }}>
      {inputs.map((input) => (
        <InputGroup key={`form-input-${input.name}`} {...input} />
      ))}
      {messages[formState] && (
        <SuccessAndErrorMessage message={messages[formState]} />
      )}
      <SubmitButton
        disabled={formState === SUBMITTING}
        submitButtonText={submitButtonText}
      />
    </Box>
  );
};

const SubmitButton = ({ submitButtonText, disabled }) => {
  const wrapperRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const cursorRef = React.useRef(null);

  return (
    <ButtonCircleHover
      type="submit"
      disabled={disabled}
      wrapperRef={wrapperRef}
      buttonRef={buttonRef}
      cursorRef={cursorRef}
      buttonText={submitButtonText}
      blue={false}
      wrapperSx={{
        marginTop: ['12.54%', '13.47%', '12.61%', '12.85%', '12.21%', '11.05%'],
        width: 'min-content'
      }}
    />
  );
};

const InputGroup = ({ type, name, label, placeholder }) => {
  return (
    <Box className="input-group">
      <Label
        htmlFor={name}
        sx={{
          color: 'biege',
          variant: 'typography.tag-500-100.bold'
        }}>
        {label}
      </Label>
      <Input
        id={name}
        type={type}
        name={name}
        placeholder={placeholder}
        required
      />
    </Box>
  );
};

export const AgreementText = ({ agreementText, sx }) => {
  return (
    <Paragraph
      type="p-300-150"
      sx={{
        gridColumn: [
          '1 / span 12',
          '1 / span 4',
          '2 / span 9',
          '2 / span 8',
          '9 / span 6',
          '11 / span 4'
        ],
        gridRow: [4, 3, 3, 3, 3, 3],
        color: 'biege',
        opacity: 0.7,
        marginTop: ['12px', '0px', '0px', '0px', '0px', '0px'],
        alignSelf: ['start', 'end', 'end', 'end', 'end', 'end'],
        ...sx
      }}>
      {agreementText}
    </Paragraph>
  );
};

export const SuccessAndErrorMessage = ({ message }) => {
  const lineRef = React.useRef();
  const textRef = React.useRef();

  React.useLayoutEffect(() => {
    gsap.set(textRef.current, {
      autoAlpha: 0,
      height: 0
    });
  }, []);

  React.useEffect(() => {
    gsap.to(textRef.current, {
      autoAlpha: 1,
      height: '100%'
    });
    gsap.fromTo(
      lineRef.current,
      {
        height: 0
      },
      {
        height: '100%',
        delay: 0.1
      }
    );
  }, []);

  return (
    <FlexWrapper
      sx={{
        gridColumnStart: ['1', '2', '3', null, null, '2'],
        gridColumnEnd: [
          'span 12',
          'span 10',
          'span 13',
          'span 11',
          'span 9',
          'span 7'
        ],
        marginTop: ['15px', '20px', '20px', '20px', '20px', '25px']
      }}>
      <Box ref={textRef} sx={{ overflow: 'hidden', height: '100%' }}>
        <Heading
          variant="bold"
          type="tag-500-100"
          as="p"
          sx={{ color: 'biege' }}>
          {message.headline}
        </Heading>
        <Paragraph
          type="p-300-150"
          as="p"
          sx={{
            color: 'biege',
            opacity: 0.7,
            paddingTop: '4px',
            '&>a': {
              textDecoration: 'underline'
            },
            '&>a:hover': {
              opacity: 1,
              color: 'red'
            }
          }}
          dangerouslySetInnerHTML={{ __html: message.text }}
        />
      </Box>
    </FlexWrapper>
  );
};
