import React from 'react';

import { gsap } from 'gsap/all';

// Pieces components
import { GridWrapper, Box, Image, Button } from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';
import { useNavigationData } from './useNavigationData';
import { GetInTouchOverlay, useGetInTouchOverlay } from 'sections/overlay';
// Local Components
// import { LanguageSwitch } from '../../components';

// components
import { ButtonCircleHover } from 'components';

export const Navigation = () => {
  const { logoLink, getInTouchButton } = useNavigationData();
  const [overlayProps, overlayControl] = useGetInTouchOverlay();
  return (
    <GridWrapper
      id="nav"
      sx={{
        bg: 'biege',
        position: 'absolute',
        paddingTop: ['13px', '15px', '24px', '24p', '23px', '17px'],
        paddingBottom: ['13px', '15px', '24px', '24p', '23px', '17px'],
        top: 0,
        left: 0,
        width: '100%',
        zIndex: '10',
        visibility: 'hidden'
      }}>
      <LogoLink svg={logoLink.image.publicURL} to={logoLink.to} />
      <GetInTouchButton
        onClick={overlayControl.handleOpenOverlay}
        buttonText={getInTouchButton.text}
      />
      {/* <LanguageSwitch sx={{ justifyContent: 'end' }} /> */}
      <GetInTouchOverlay {...overlayProps} />
    </GridWrapper>
  );
};

const LogoLink = ({ svg, to }) => (
  <Box
    className="navbar_, '20 / span 5'_logo"
    sx={{
      gridColumn: [
        '1 / span 5',
        '1 / span 4',
        '1 / span 6',
        '1 / span 5',
        '1 / span 4',
        '1 / span 4'
      ],
      width: ['100%', '100%', '90%', '90%', '100%', '80%'],
      alignSelf: 'center'
    }}>
    <NavigationLink variant="navigation" to={to}>
      <Image src={svg} alt="" sx={{ display: 'block' }} />
    </NavigationLink>
  </Box>
);

const GetInTouchButton = ({ buttonText, onClick }) => {
  const wrapperRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const cursorRef = React.useRef(null);

  return (
    <ButtonCircleHover
      wrapperRef={wrapperRef}
      buttonRef={buttonRef}
      cursorRef={cursorRef}
      buttonText={buttonText}
      onClick={onClick}
      wrapperSx={{
        alignSelf: 'center',
        gridColumn: [
          '8 / span 5',
          '9 / span 4',
          '19 / span 6',
          '20 / span 5',
          '20 / span 5',
          '21 / span 4'
        ],
        justifySelf: 'end'
      }}
    />
  );
};
