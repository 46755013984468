import React from 'react';

import { gsap } from 'gsap/all';

export const TargetGroupVector = () => {
  const asteriskRef = React.useRef(null);

  React.useEffect(() => {
    let tl = gsap.timeline({
        scrollTrigger: {
          trigger: asteriskRef.current,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 0.5
        }
      });
    
      tl.to(asteriskRef.current, {
        rotate: 360,
        transformOrigin: '50% 50%',
        ease: 'none'
      });
    
      const killAnimation = () => {
        tl.scrollTrigger.kill(true);
        tl.pause(0).kill(true);
      };
    
      return killAnimation;
  }, []);

  return (
    <svg
      width="418"
      height="294"
      viewBox="0 0 418 294"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.2288 281.086C17.9649 270.105 6.31193 256.082 2.26984 239.015C-1.53606 221.884 2.04626 203.677 13.0168 184.396C20.9019 170.537 30.2073 158.386 40.933 147.942C51.8091 137.583 63.4837 129.675 75.9568 124.217C88.5155 118.608 101.379 115.966 114.548 116.291C127.803 116.465 140.751 120.155 153.393 127.361C171.754 137.827 182.762 151.583 186.417 168.628C190.223 185.759 186.512 204.192 175.285 223.925C167.314 237.935 158.084 250.129 147.594 260.508C137.255 270.973 126 279.021 113.828 284.65C101.892 290.215 89.415 292.878 76.3966 292.639C63.3782 292.4 50.3222 288.549 37.2288 281.086ZM48.0624 263.621C57.0923 268.768 66.4296 271.098 76.0743 270.611C85.719 270.124 95.253 267.379 104.676 262.377C114.185 257.224 123.133 250.255 131.52 241.47C140.057 232.771 147.582 222.697 154.096 211.249C162.238 196.938 165.151 183.936 162.836 172.243C160.606 160.399 153.621 151.131 141.882 144.439C132.852 139.292 123.44 136.919 113.645 137.321C103.935 137.572 94.2934 140.155 84.7197 145.072C75.2316 149.837 66.1762 156.645 57.5534 165.495C49.0164 174.194 41.4052 184.419 34.7201 196.168C26.6636 210.328 23.8256 223.373 26.206 235.303C28.5865 247.233 35.872 256.673 48.0624 263.621Z"
        fill="#FFBC00"
      />
      <path
        d="M410.868 201.273L410.89 201.294L410.912 201.315C416.935 207.085 416.146 217.081 408.956 222.564C370.37 249.823 320.481 254.445 278.216 236.182C263.183 229.586 249.189 220.572 237.179 208.426L237.172 208.419L237.164 208.412C201.79 173.108 191.239 122.978 205.478 78.9379L205.482 78.9255C209.898 65.0635 216.773 51.9176 225.87 39.977L225.882 39.9615L225.894 39.9458C231.077 32.9586 241.222 32.1426 247.437 38.2982C247.438 38.2994 247.439 38.3005 247.44 38.3016L410.868 201.273Z"
        fill="#FF310D"
        stroke="#FF310D"
        strokeWidth="6.10813"
      />
      <path
        ref={asteriskRef}
        d="M176.068 65.0733L157.523 36.8813L189.03 42.288L189.23 26.4541L156.925 33.2125L170.684 3.66882L155.13 0L153.136 32.247L127.811 11.5858L120.034 25.6818L150.743 35.3366L120.432 51.7497L131.201 63.5286L153.535 38.0399L160.913 70.6731L176.068 65.0733Z"
        fill="#FDF8E4"
      />
    </svg>
  );
};
