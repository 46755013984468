module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-thepuzzlers-core/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-thepuzzlers-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/thepuzzlers-org/websites/enterprise.thepuzzlers.io-v2/src/intl","languages":["en"],"locales":{"default":"en","translations":[]},"translatedPaths":[],"secondPath":null,"redirect":false,"wrapProvider":true},
    }]
