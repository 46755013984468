// external dependencies
import React from 'react';

//media
import headingDecoration from 'assets/footer/tick.svg';
import enterpriseLogo from 'assets/footer/enterprise-the-puzzlers-logo-negative.svg';
import regularLogo from 'assets/footer/the-puzzlers-logo.svg';

// Pieces components
import {
  Section,
  Heading,
  Box,
  Paragraph,
  Input,
  GridItem,
  Label,
  FlexWrapper,
  fullWidthMinusMargins
} from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

//project component
import {
  Vector,
  IconButton,
  ButtonCircleHover,
  FooterDecorationMobile,
  FooterDecorationDesktop
} from 'components';

import { useFooterData } from './useFooterData';

import { Form } from './components/Form';

export const Footer = () => {
  const {
    heading,
    form,
    agreementText,
    enterpriseParagraph,
    regularParagraph,
    servicesParagraph,
    socialLinks,
    legalLink,
    policyLink,
    messages
  } = useFooterData();

  return (
    <Section
      id="footer"
      as="footer"
      sx={{
        bg: 'blue',
        paddingBottom: [null, null, null, null, '2.51%', '3.26%'],
        mt: '-6%'
      }}>
      <GridSpacer
        height={['27.28%', '15.51%', '22.63%', '9.44%', '12.41%', '20%']}
      />
      <Headline heading={heading} />
      <Form form={form} messages={messages} />
      <AgreementText agreementText={agreementText} />
      <GridSpacer
        className="form-spacer"
        height={['20.07%', '12.8%', '19.09%', '13.08%', '7.01%', '6.57%']} //spacer is used instead of margin top for this case, to make easier align the content at the same row,
        sx={{
          gridRow: [null, null, null, null, null, 4]
        }}
      />
      <EnterpriseLogo />
      <EnterpriseParagraph paragraph={enterpriseParagraph} />
      <GridSpacer
        height={['13.17%', '7.76%', '8.06%', '8.14%', '4.81%', '2.5%']} //spacer is used instead of margin top for this case, to make easier align the content at the same row
      />
      <RegularLogo />
      <RegularParagraph paragraph={regularParagraph} />
      <GridSpacer
        height={['14.11%', '7.76%', '8.06%', '5.38%', '4.81%', '2.5%']} //spacer is used instead of margin top for this case, to make easier align the content at the same row
      />
      <ServiceParagraph paragraph={servicesParagraph} />
      <SocialLinks socialLinks={socialLinks} />
      <LegalAndPolicyLinks legalLink={legalLink} policyLink={policyLink} />
      <FooterDecoration />
    </Section>
  );
};

export const GridSpacer = ({ height, className = '', sx, ...props }) => (
  <GridItem
    className={`grid-spacer ${className}`}
    sx={{ marginTop: height, ...sx }}
    {...props}
  />
);

export const Headline = ({ heading, sx }) => {
  return (
    <Box
      className="footer__heading"
      sx={{
        gridColumn: [
          '1 / span 12',
          '1 / span 4',
          '2 / span 8',
          '2 / span 7',
          '9 / span 5',
          '11 / span 4'
        ],
        gridRow: 2,
        ...sx
      }}>
      <Vector
        className="footer__heading__decoration"
        svg={headingDecoration}
        alt=""
        sx={{
          width: ['11%', '20%', '23%', '16%', '26%', '26%'],
          transform: [
            null,
            null,
            'translateX(-25%)',
            'translateX(-36%)',
            'translateX(-36%)',
            'translateX(-36%)'
          ]
        }}
      />
      <Heading
        as="h2"
        type="h2-1000-110"
        sx={{
          color: 'biege',
          marginTop: ['3.76%', '7.25%', '4.4%', '6.14%', '5.95%', '6%']
        }}>
        {heading}
      </Heading>
    </Box>
  );
};

export const AgreementText = ({ agreementText, sx }) => {
  return (
    <Paragraph
      type="p-300-150"
      sx={{
        gridColumn: [
          '1 / span 12',
          '1 / span 4',
          '2 / span 9',
          '2 / span 8',
          '9 / span 6',
          '11 / span 4'
        ],
        gridRow: [4, 3, 3, 3, 3, 3],
        color: 'biege',
        opacity: 0.7,
        marginTop: ['12px', '0px', '0px', '0px', '0px', '0px'],
        alignSelf: ['start', 'end', 'end', 'end', 'end', 'end'],
        ...sx
      }}>
      {agreementText}
    </Paragraph>
  );
};

//legal link typography not complete yet

const EnterpriseLogo = () => (
  <Vector
    className="enterprise-the-puzzlers-logo"
    svg={enterpriseLogo}
    alt=""
    sx={{
      gridColumn: [
        '1 / span 5',
        '1 / span 4',
        '2 / span 7',
        '2 / span 6',
        '9 / span 4',
        '11 / span 4'
      ],
      gridRow: [6, 5, 5, 5, 5, 5],
      width: ['100%', '94%', '100%', '91%', '91%', '75%']
    }}
  />
);

const EnterpriseParagraph = ({ paragraph }) => (
  <LogoParagraph
    paragraph={paragraph}
    sx={{
      gridRow: [7, 5, 5, 5, 5],
      paddingRight: [null, null, null, null, '6%', '9%']
    }}
  />
);

const RegularLogo = () => (
  <Vector
    className="regular-the-puzzlers-logo"
    svg={regularLogo}
    alt=""
    sx={{
      gridColumn: [
        '1 / span 6',
        '1 / span 4',
        '2 / span 7',
        '2 / span 6',
        '9 / span 4',
        '11 / span 4'
      ],
      width: [null, null, null, null, null, '84%'],
      gridRow: [9, 7, 7, 7, 7, 7]
    }}
  />
);

const RegularParagraph = ({ paragraph }) => (
  <LogoParagraph
    paragraph={paragraph}
    sx={{
      gridRow: [10, 7, 7, 7, 7],
      paddingRight: [null, null, null, null, '11%', '11%']
    }}
  />
);

const ServiceParagraph = ({ paragraph }) => (
  <Paragraph
    type="h6-500-150"
    sx={{
      gridColumn: [
        '1 / span 11',
        '6 / span 6',
        '13 / span 10',
        '12 / span 9',
        '16 / span 6',
        '17 / span 5'
      ],
      gridRow: [12, 9, 9, 9, 9, 9],
      color: 'biege'
    }}
    dangerouslySetInnerHTML={{ __html: paragraph }}
  />
);

export const SocialLinks = ({ socialLinks, sx }) => {
  return (
    <FlexWrapper
      className="social-links"
      sx={{
        gridColumn: [
          '8 / span 5',
          '1 / span 4',
          '2 / span 7',
          '2 / span 6',
          '9 / span 4',
          '11 / span 3'
        ],
        gridRow: [13, 9, 9, 9, 9, 9],
        justifyContent: [
          'flex-end',
          'unset',
          'unset',
          'unset',
          'unset',
          'unset'
        ],
        alignSelf: [null, 'center', 'center', 'center', 'center', 'center'],
        marginTop: ['43.1%', '0px', '0px', '0px', '0px', '0px'],
        '& > .social-icon-button': {
          marginLeft: ['24px', '24px', '24px', '24px', '24px', '24px'],
          '&:first-of-type': {
            marginLeft: 0
          }
        },
        ...sx
      }}>
      {socialLinks.map((link) => (
        <IconButton
          key={link.name}
          className="social-icon-button"
          as="a"
          target="_blank"
          href={link.href}
          svg={link.icon.publicURL}
          id={`${link.name}-social`}
          sx={{
            width: ['24px', '32px', '32px', '32px', '32px', '32px']
          }}
        />
      ))}
    </FlexWrapper>
  );
};

const LegalAndPolicyLinks = ({ legalLink, policyLink }) => {
  return (
    <FlexWrapper
      className="legal-and-policy-links"
      sx={{
        alignItems: 'center',
        justifySelf: ['end', 'unset', 'end', 'end', 'unset'],
        alignSelf: [null, null, 'end', 'end', 'center'],
        gridColumn: [
          '5 / span 8',
          '1 / span 6',
          '17 / span 8',
          '18 / span 7',
          '1 / span 6',
          '1 / span 6'
        ],
        gridRow: [14, 10, 10, 10, 9, 9],
        marginTop: ['9.96%', '55.71%', '0%', '0%', '0%', '0%'],
        marginBottom: [null, null, '69px', '24px', '0%', '0%']
      }}>
      <NavigationLink variant="navigation" to={legalLink.to}>
        {legalLink.name}
      </NavigationLink>
      <Box
        className="white-dot"
        sx={{
          height: '4px',
          width: '4px',
          borderRadius: '4px',
          bg: 'beige',
          mx: ['12px', '12px', '12px', '12px', '16px', '16px']
        }}
      />
      <NavigationLink variant="navigation" to={policyLink.to}>
        {policyLink.name}
      </NavigationLink>
    </FlexWrapper>
  );
};

export const FooterDecoration = ({ sx }) => (
  <Box
    className="footer-decoration"
    sx={{
      gridColumn: [
        '1 / span 12',
        '1 / span 12',
        '1 / span 12',
        '1 / span 9',
        '1 / span 6',
        '1 / span 6'
      ],
      gridRow: [15, 11, 10, 10, '2 /span 2', '2 /span 3'],
      marginTop: ['18.73%', '13.77%', '52.4%', '44.63%', '13.64%'],
      justifySelf: ['start', 'start', 'start', 'start', 'start', 'start'],
      width: ['117%', '100%', '118%', '124%', '145%', '126%'],
      marginRight: ['4%', '0px', '0px', '0px', '0px', '5%'],
      marginLeft: [
        fullWidthMinusMargins[0],
        0,
        fullWidthMinusMargins[2],
        fullWidthMinusMargins[3],
        fullWidthMinusMargins[4],
        fullWidthMinusMargins[5]
      ],
      transform: [null, null, null, null, 'translateX(-5%)', 'translateX(-5%)'],
      ...sx
    }}>
    <Box
      sx={{
        display: ['block', 'block', 'block', 'block', 'none', 'none'],
        width: '100%',
        '& > svg': {
          overflow: 'hidden',
          width: '100%',
          display: 'block'
        }
      }}>
      <FooterDecorationMobile />
    </Box>
    <Box
      sx={{
        display: ['none', 'none', 'none', 'none', 'block', 'block'],
        width: '100%',
        '& > svg': {
          overflow: 'visible',
          width: '100%',
          display: 'block'
        }
      }}>
      <FooterDecorationDesktop />
    </Box>
  </Box>
);

//============ LOCAL REUSED COMPONENT ==================
const LogoParagraph = ({ paragraph, sx }) => (
  <Paragraph
    type="body-300-135"
    sx={{
      gridColumn: [
        '1 / span 9',
        '6 / span 5',
        '13 / span 10',
        '12 / span 9',
        '16 / span 7',
        '17 / span 6'
      ],
      marginTop: ['15px', '0px', '0px', '0px', '0px'],
      color: 'biege',
      ...sx
    }}>
    {paragraph}
  </Paragraph>
);
