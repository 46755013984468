import React from 'react';
import Lottie from 'lottie-react';

// pieces
import { Box } from '@thepuzzlers/pieces';

export const LottieWrapper = ({
  animationData,
  loop = true,
  autoplay = true,
  lottieRef,
  wrapperRef,
  sx
}) => {
  return (
    <Box ref={wrapperRef} className="lottie-container" sx={sx}>
      <Lottie
        lottieRef={lottieRef}
        autoplay={autoplay}
        loop={loop}
        animationData={animationData}
      />
    </Box>
  );
};
