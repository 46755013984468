import React from 'react';

// pieces
import { Box, Image } from '@thepuzzlers/pieces';

export const Vector = ({ vectorRef, svg, alt, sx, ...props }) => {
  return (
    <Box ref={vectorRef} sx={{ display: 'flex', ...sx }} {...props}>
      <Image src={svg} alt={alt} sx={{ maxWidth: '100%', display: 'block' }} />
    </Box>
  );
};
