import { useStaticQuery, graphql } from 'gatsby';

export function useNavigationData() {
  const { data } = useStaticQuery(graphql`
    query {
      data: navigationDataJson {
        logoLink {
          image {
            publicURL
          }
          to
        }
        getInTouchButton {
          text
        }
      }
    }
  `);

  return data;
}
